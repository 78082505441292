<template>
  <section class="conAllSettings">
    <v-container fluid>
      <v-row>
        <v-col cols="12">
          <div class="headerSettingPage headerOfPage">Settings</div>
        </v-col>
      </v-row>
    </v-container>
    <section class="contentSettingPage mt-3">
      <v-container fluid>
        <v-row>
          <v-col lg="3" md="4" sm="6" cols="12" v-if="user.role_id == 1">
            <router-link :to="{ name: 'settingDepartments' }">
              <v-card
                class="mx-auto fill-height conCard"
                max-width="100%"
                elevation="0"
              >
                <div class="fill-height py-7 d-flex justify-center">
                  <div class="fill-height conOfCard">
                    <div class="conImg">
                      <img
                        src="@/assets/img/settings/departements.png"
                        alt=""
                      />
                    </div>
                    <div class="conTxt">DEPARTMENTS</div>
                  </div>
                </div>
              </v-card>
            </router-link>
          </v-col>
          <v-col lg="3" md="4" sm="6" cols="12" v-if="user.role_id == 1">
            <router-link :to="{ name: 'settingTeams' }">
              <v-card
                class="mx-auto fill-height conCard"
                max-width="100%"
                elevation="0"
              >
                <div class="fill-height py-7 d-flex justify-center">
                  <div class="fill-height conOfCard">
                    <div class="conImg">
                      <img src="@/assets/img/settings/teams.png" alt="" />
                    </div>
                    <div class="conTxt">TEAMS</div>
                  </div>
                </div>
              </v-card>
            </router-link>
          </v-col>

          <v-col lg="3" md="4" sm="6" cols="12" v-if="user.role_id == 1">
            <router-link :to="{ name: 'settingUsers' }">
              <v-card
                class="mx-auto fill-height conCard"
                max-width="100%"
                elevation="0"
              >
                <div class="fill-height py-7 d-flex justify-center">
                  <div class="fill-height conOfCard">
                    <div class="conImg">
                      <img src="@/assets/img/settings/users.png" alt="" />
                    </div>
                    <div class="conTxt">USERS</div>
                  </div>
                </div>
              </v-card>
            </router-link>
          </v-col>

          <v-col lg="3" md="4" sm="6" cols="12" v-if="user.role_id == 1">
            <router-link :to="{ name: 'settingRoles' }">
              <v-card
                class="mx-auto fill-height conCard"
                max-width="100%"
                elevation="0"
              >
                <div class="fill-height py-7 d-flex justify-center">
                  <div class="fill-height conOfCard">
                    <div class="conImg">
                      <img src="@/assets/img/settings/roles.png" alt="" />
                    </div>
                    <div class="conTxt">ROLES</div>
                  </div>
                </div>
              </v-card>
            </router-link>
          </v-col>

          <v-col lg="3" md="4" sm="6" cols="12" v-if="user.role_id == 1">
            <router-link :to="{ name: 'settingRequestType' }">
              <v-card
                class="mx-auto fill-height conCard"
                max-width="100%"
                elevation="0"
              >
                <div class="fill-height py-7 d-flex justify-center">
                  <div class="fill-height conOfCard">
                    <div class="conImg">
                      <img src="@/assets/img/settings/requestType.png" alt="" />
                    </div>
                    <div class="conTxt">REQUEST TYPE</div>
                  </div>
                </div>
              </v-card>
            </router-link>
          </v-col>

          <v-col lg="3" md="4" sm="6" cols="12" v-if="user.role_id == 1">
            <router-link :to="{ name: 'settingCurrency' }">
              <v-card
                class="mx-auto fill-height conCard"
                max-width="100%"
                elevation="0"
              >
                <div class="fill-height py-7 d-flex justify-center">
                  <div class="fill-height conOfCard">
                    <div class="conImg">
                      <img src="@/assets/img/settings/currency.png" alt="" />
                    </div>
                    <div class="conTxt">CURRENCY</div>
                  </div>
                </div>
              </v-card>
            </router-link>
          </v-col>

          <v-col lg="3" md="4" sm="6" cols="12" v-if="user.role_id == 1">
            <router-link :to="{ name: 'settingCountries' }">
              <v-card
                class="mx-auto fill-height conCard"
                max-width="100%"
                elevation="0"
              >
                <div class="fill-height py-7 d-flex justify-center">
                  <div class="fill-height conOfCard">
                    <div class="conImg">
                      <img src="@/assets/img/settings/countries.png" alt="" />
                    </div>
                    <div class="conTxt">COUNTRIES</div>
                  </div>
                </div>
              </v-card>
            </router-link>
          </v-col>
          <v-col lg="3" md="4" sm="6" cols="12">
            <router-link :to="{ name: 'settingCompanies' }">
              <v-card
                class="mx-auto fill-height conCard"
                max-width="100%"
                elevation="0"
              >
                <div class="fill-height py-7 d-flex justify-center">
                  <div class="fill-height conOfCard">
                    <div class="conImg">
                      <img src="@/assets/img/settings/factory.png" alt="" />
                    </div>
                    <div class="conTxt">COMPANIES</div>
                  </div>
                </div>
              </v-card>
            </router-link>
          </v-col>
          <v-col lg="3" md="4" sm="6" cols="12" v-if="user.role_id == 1">
            <router-link :to="{ name: 'settingNotification' }">
              <v-card
                class="mx-auto fill-height conCard"
                max-width="100%"
                elevation="0"
              >
                <div class="fill-height py-7 d-flex justify-center">
                  <div class="fill-height conOfCard">
                    <div class="conImg">
                      <img
                        src="@/assets/img/settings/notification.png"
                        alt=""
                      />
                    </div>
                    <div class="conTxt">Notifications</div>
                  </div>
                </div>
              </v-card>
            </router-link>
          </v-col>
          <v-col lg="3" md="4" sm="6" cols="12" v-if="user.role_id == 1">
            <router-link :to="{ name: 'settingActivityLogs' }">
              <v-card
                class="mx-auto fill-height conCard"
                max-width="100%"
                elevation="0"
              >
                <div class="fill-height py-7 d-flex justify-center">
                  <div class="fill-height conOfCard">
                    <div class="conImg">
                      <v-icon class="d-flex justify-center"
                        >mdi-account-group</v-icon
                      >
                    </div>
                    <div class="conTxt">Activity logs</div>
                  </div>
                </div>
              </v-card>
            </router-link>
          </v-col>
          <v-col lg="3" md="4" sm="6" cols="12" v-if="user.role_id == 1">
            <router-link :to="{ name: 'permissions' }">
              <v-card
                class="mx-auto fill-height conCard"
                max-width="100%"
                elevation="0"
              >
                <div class="fill-height py-7 d-flex justify-center">
                  <div class="fill-height conOfCard">
                    <div class="conImg">
                      <v-icon class="d-flex justify-center">mdi-lock</v-icon>
                    </div>
                    <div class="conTxt">Permissions</div>
                  </div>
                </div>
              </v-card>
            </router-link>
          </v-col>
        </v-row>
      </v-container>
    </section>
  </section>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  computed: {
    ...mapGetters(["user"]),
  },
};
</script>
<style lang="scss" scoped>
@import "./_all-setting.scss";
</style>
